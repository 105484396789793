export function formatarReal(valor) {
  if (typeof valor !== "number") {
    valor = Number(valor);
    if (isNaN(valor)) {
      valor = 0;
    }
  }

  return valor.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
}

export function formatarPorcentagem(valor) {
  if (typeof valor !== "number") {
    valor = Number(valor);
    if (isNaN(valor)) {
      valor = 0;
    }
  }

  return valor.toLocaleString("pt-BR", {
    style: "percent",
    minimumFractionDigits: 2,
  });
}

export function formatarData(data) {
  if (!data) {
    return "";
  }

  return new Date(data).toLocaleDateString("pt-BR");
}

export function formatarDataHora(data) {
  if (!data) {
    return "";
  }

  return new Date(data).toLocaleString("pt-BR");
}

export function formatarDataHoraUTC(data) {
  if (!data) {
    return "";
  }

  return new Date(data).toLocaleString("pt-BR", { timeZone: "UTC" });
}
