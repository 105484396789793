import { useEffect, useState } from "react";

import ProspectTable from "./ProspectTable";

import { Text } from "../../Components/Text";
import { Card, CardBody, CardHeader } from "../../Components/Card";
import { Column, ContainerFlex, Row } from "../../Components/Layout";

import { formatarReal } from "../../Components/utilities/Format";

const Content = ({ prospectList }) => {
  const [totalCount, setTotalCount] = useState(0);
  const [totalValor, setTotalValor] = useState(0);

  const handleTotal = () => {
    let valor = 0;
    prospectList.forEach((element) => {
      valor += Number(element.value);
    });
    setTotalValor(valor);
  };

  useEffect(() => {
    if (Array.isArray(prospectList)) {
      setTotalCount(prospectList.length);
      handleTotal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prospectList]);

  return (
    <ContainerFlex full>
      <Row>
        <Column col="12" md="3">
          <ContainerFlex bgColor="white" full rounded padding="3" className="shadow-sm">
            <ContainerFlex row width between>
              <h3>{totalCount ? totalCount : 0}</h3>
              <ContainerFlex
                style={{ width: "40px", height: "40px" }}
                border
                rounded
                center
                middle
              >
                <i className="fas fa-arrow-up-1-9 "></i>
              </ContainerFlex>
            </ContainerFlex>
            <Text tag="small">Quantidade</Text>
          </ContainerFlex>
        </Column>
        <Column col="12" md="3">
          <ContainerFlex bgColor="white" full rounded padding="3" className="shadow-sm">
            <ContainerFlex row width between>
              <h4>{formatarReal(totalValor ? totalValor : 0)}</h4>
              <ContainerFlex
                style={{ width: "40px", height: "40px" }}
                border
                rounded
                center
                middle
              >
                <i className="fa-solid fa-dollar-sign"></i>
              </ContainerFlex>
            </ContainerFlex>
            <Text tag="small">Valor de Fechamento</Text>
          </ContainerFlex>
        </Column>
      </Row>
      <Card bgColor="white" marginTop="3">
        <CardHeader bgColor="ydental-dark" txtColor="white">
          <Text tag="h5" marginBottom="0">Conversões</Text>
        </CardHeader>
        <CardBody>
          <ProspectTable listProspect={prospectList} />
        </CardBody>
      </Card>
    </ContainerFlex>
  );
};

export default Content;
