import { useEffect, useState } from "react";

import { Text } from "../../../Components/Text";
import { ContainerFlex } from "../../../Components/Layout";
import { formatarReal } from "../../../Components/utilities/Format";

const Sources = ({ list }) => {
  const [listSourcesByDate, setListSourcesByDate] = useState([]);

  useEffect(() => {
    if (list && Object.keys(list).length > 0) {
      const anuncios = Object.entries(list)
        .map(([anuncio, { count, sum }]) => ({ anuncio, count, sum }))
        .sort((a, b) => b.count - a.count);

      setListSourcesByDate(anuncios);
    } else {
      setListSourcesByDate([]);
    }
  }, [list]);

  return (
    <ContainerFlex padding="3" bgColor="white" className="shadow-sm">
      <Text tag="h5">Origens Fechamentos</Text>
      <hr />

      <ContainerFlex style={{ maxHeight: "300px", scrollbarWidth: "thin" }} className="overflow-auto" >
        {listSourcesByDate.length > 0 ? (
          listSourcesByDate.map(({ anuncio, count, sum }) => (

            <ContainerFlex
              key={anuncio}
              padding="1"
              marginBottom="1"
              border
              rounded
              between
            >
              <ContainerFlex middle>
                <Text fontWeight="bold">{anuncio}</Text>
              </ContainerFlex>
              <ContainerFlex middle>
                <Text fontWeight="bold" marginRight="2">{count}</Text>
                <Text style={{ fontSize: '10px' }}>({formatarReal(sum)})</Text>
              </ContainerFlex>
            </ContainerFlex>
          ))
        ) : (
          <Text>Nenhuma origem encontrada</Text>
        )}
      </ContainerFlex>
    </ContainerFlex>
  );
};

export default Sources;
