import { useEffect, useState } from "react";

import { Text } from "../../../Components/Text";
import { ContainerFlex } from "../../../Components/Layout";

const Sources = ({ list }) => {
  const [listSourcesByDate, setListSourcesByDate] = useState([]);

  useEffect(() => {
    if (list && Object.keys(list).length > 0) {
      const origens = Object.entries(list)
        .map(([origem, count]) => ({ origem, count }))
        .sort((a, b) => b.count - a.count);

      setListSourcesByDate(origens);
    } else {
      setListSourcesByDate([]);
    }
  }, [list]);

  return (
    <ContainerFlex padding="3" bgColor="white" className="shadow-sm">
      <Text tag="h5">Origens</Text>
      <hr />
      <ContainerFlex style={{ maxHeight: "300px", scrollbarWidth: "thin" }} className="overflow-auto">
        {listSourcesByDate.length > 0 ? (
          listSourcesByDate.map(({ origem, count }) => (
            <ContainerFlex
              key={origem}
              padding="1"
              marginBottom="1"
              border
              rounded
              between
            >
              <Text>{origem}</Text>
              <Text fontWeight="bold">{count}</Text>
            </ContainerFlex>
          ))
        ) : (
          <Text>Nenhuma origem encontrada</Text>
        )}
      </ContainerFlex>
    </ContainerFlex>
  );
};

export default Sources;
