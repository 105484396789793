import React from "react";

import MenuList from "./MenuList";

// import { NavBar } from "../../Components/Nav";
import Image from "../../Components/Image";

import Logo from "../../Assets/images/logo192.png";
import { ContainerFlex } from "../../Components/Layout";

const Sidebar = () => {
  return (
    <ContainerFlex
      height="vh-100"
      // txtColor="white"
      // paddingLeftRight="2"
      className="d-flex flex-shrink-0 text-white bg-dark sidebar"
      top
      column
    >
      <ContainerFlex padding="3">
        <Image src={Logo} width />
      </ContainerFlex>
      <hr />
      <ContainerFlex
        overflow
        width
        style={{
          overflow: "auto",
          scrollbarWidth: "thin",
          scrollbarColor: "#1a1c2c #2b2d42 ",
        }}
      >
        <MenuList />
      </ContainerFlex>
    </ContainerFlex>
  );
};

export default Sidebar;
